<template>
    <div class="menu">
        <div class="menu-logo"><img src="@/assets/images/logo.png" v-bind="logoAttrs"></div>
        <nav>
            <ul>
                <template v-for="(menu, key) in menu">
                    <li :key="key">
                        <router-link :to="{ name: menu.link }" class="is-current">{{ menu.text }}</router-link>
                    </li>
                </template>
            </ul>
        </nav>
    </div>
</template>

<script>
    "use strict";

    import common from "@/mixins/common";

    export default {
        "mixins": [
            common,
        ],
        data() {
            return {
                "logoAttrs": {
                    "alt": "",
                    "title": "",
                },
                "menu": {
                    "search": {
                        "link": "search",
                        "text": "品番検索",
                    },
                },
            };
        },
    };
</script>
