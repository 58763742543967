<template>
    <div class="header">
        <div class="l-3-00-m--all">
            <div>
            </div>
            <h1>
                {{ $root.pagetitle }}
            </h1>
            <nav>
                <ul>
                    <li class="logoutbutton">
                        <button type="button" @click="handleLogOut()">ログアウト</button>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
    "use strict";

    import common from "@/mixins/common";

    export default {
        "mixins": [
            common,
        ],
        data() {
            return {
                "active": "is-current",
            };
        },
        "methods": {
            handleLogOut() {
                this.$router.push({ "name": "offices_logout" });
            },
        },
    };
</script>

<style>
    .logoutbutton {
        margin: 0 20px 0 0;
    }
</style>
